import { Box, Grid, Stack, Toolbar, Typography, Switch, Dialog, DialogContent, DialogTitle, TextField, FormControl, InputLabel, Select, MenuItem, CircularProgress, Button, Pagination } from '@mui/material';
import React, { useEffect, useState } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import Images from '../../utils/Images';
import axios from 'axios';
import { API } from '../../utils/Api';
import { useSelector } from 'react-redux';
import SendIcon from '@mui/icons-material/Send';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import { ContentCopyOutlined, CopyAllOutlined } from '@mui/icons-material';

function PaymentTransactions() {
    const token = localStorage.getItem("token");
    const userRole = localStorage.getItem("role");
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(false);
    const userData = useSelector((state) => state.login.user);
    const [noOfRecords, setNoOfRecords] = useState(20);
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 20;

    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, '0');
    const day = today.getDate().toString().padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;
    const unixTimestamp = Math.floor(new Date(formattedDate).getTime() / 1000);
    const endOfDay = new Date(`${formattedDate}T23:59:59`);
    const endOfDayUnixTimestamp = Math.floor(endOfDay.getTime() / 1000);

    useEffect(() => {
        getStockTransactions();
    }, [noOfRecords]);

    const handleSearch = (value) => {
        setSearchQuery(String(value).trim());
    };

    const getStockTransactions = async () => {
        setLoading(true);
        try {
            const params = {
                from: unixTimestamp,
                to: endOfDayUnixTimestamp,
                count: noOfRecords,
                skip: 0
            };
            const response = await axios.get(`${API.BASE_URL}payment/getPaymentTransaction`, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
                params
            }
            );
            setTransactions(response?.data?.data?.response?.items)
            setLoading(false);
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleChange = (event) => {
        setNoOfRecords(event.target.value);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const filteredTransactions = transactions.filter((transaction) => {
        const searchLower = searchQuery.toLowerCase();
        return (
            transaction?.email?.toLowerCase().includes(searchLower) ||
            transaction?.contact?.toLowerCase().includes(searchLower) ||
            transaction?.method?.toLowerCase().includes(searchLower) ||
            transaction?.status?.toLowerCase().includes(searchLower) || 
            transaction?.acquirer_data?.rrn?.toLowerCase().includes(searchLower) ||
            String(transaction?.amount / 100).includes(searchLower)
        );
    });

    function highlightText(text, query) {
        if (!query) return text;
        const parts = text?.split(new RegExp(`(${query})`, 'gi'));
        return parts?.map((part, index) =>
            part?.toLowerCase() === query?.toLowerCase() ? (
                <span key={index} style={{ backgroundColor: 'yellow', fontWeight: 'bold' }}>
                    {part}
                </span>
            ) : (
                part
            )
        );
    }
    
    const copyToClipboard = (transaction) => {
        if (transaction?.order_id) {
            navigator.clipboard.writeText(transaction.order_id)
                .then(() => {
                    toast.success('RazorPay Order ID copied to clipboard!');
                })
                .catch(() => {
                    toast.error('Failed to copy Order ID.');
                });
        }
    };

    return (
        <>
            <Box className="dashBoard">
                <Toolbar />
                <Grid container justifyContent={'center'}>
                    <Grid item md={11} flexDirection={'column'}>
                        <Grid container md={12} justifyContent={'space-evenly'} sx={{ marginTop: '35px' }}>
                            <Grid item md={12}>
                                <Typography sx={{ fontSize: '16px', margin: '16px 0 30px 40px', textAlign: 'center' }}>Payment Transactions</Typography>
                            </Grid>
                            <Grid item md={4}>
                                <label style={{ paddingLeft: '10px', marginBottom: '17px' }}>Search</label>
                                <TextField
                                    fullWidth
                                    placeholder='Enter Any Transaction Detail'
                                    variant="outlined"
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                    sx={{
                                        backgroundColor: '#fff',
                                        marginTop: '5px',
                                        marginLeft: '5px',
                                    }}
                                />
                            </Grid>
                            <Grid item md={2}>
                                <label style={{ paddingLeft: '10px' }}>Records</label>
                                <FormControl
                                    fullWidth
                                    variant="outlined"
                                    sx={{
                                        backgroundColor: '#fff',
                                        marginTop: '5px',
                                        marginLeft: '5px',
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#cecece',
                                            borderWidth: '2px',
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            borderRadius: '10px',
                                            '&.Mui-focused fieldset': {
                                                borderColor: '#cecece',
                                            },
                                        },
                                        '& input': {
                                            height: '40px',
                                            width: '80%'
                                        },
                                    }}
                                    style={{ height: '56px', width: '80%' }}
                                >
                                    <Select
                                        value={noOfRecords}
                                        onChange={handleChange}
                                        displayEmpty
                                        sx={{
                                        }}
                                    >
                                        <MenuItem value={20}>20</MenuItem>
                                        <MenuItem value={50}>50</MenuItem>
                                        <MenuItem value={100}>100</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        {loading ? (
                            <Box sx={{ height: '100px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <CircularProgress />
                                <Typography sx={{ paddingLeft: '10px' }}>Fetching Transactions</Typography>
                            </Box>
                        ) : filteredTransactions.length > 0 ? ( 
                            <>
                                <>

                                    <Grid container md={userRole !== 'CASHIER' ? 11 : 11} sx={{ boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)', borderRadius: '8px', padding: '0px 0px 0px 0px', margin: '21px 0px 25px 10px' }}>
                                        <Grid item md={1} sx={{ padding: '20px 10px 20px 10px', borderRight: '1px solid grey' }}>
                                            <Typography sx={{ textAlign: 'center', fontSize: '15px' }}>No.</Typography>
                                        </Grid>
                                        <Grid item md={3} sx={{ padding: '20px 0px 20px 0px', borderRight: '1px solid grey' }}>
                                            <Typography sx={{ textAlign: 'center', fontSize: '15px' }}>Customer Details</Typography>
                                        </Grid>
                                        <Grid item md={2} sx={{ padding: '20px 0px 20px 0px', borderRight: '1px solid grey' }}>
                                            <Typography sx={{ textAlign: 'center', fontSize: '15px' }}>Method</Typography>
                                        </Grid>
                                        <Grid item md={2} sx={{ padding: '20px 0px 20px 0px', borderRight: '1px solid grey' }}>
                                            <Typography sx={{ textAlign: 'center', fontSize: '15px' }}>Ref No.</Typography>
                                        </Grid>
                                        <Grid item md={2} sx={{ padding: '20px 0px 20px 0px', borderRight: '1px solid grey' }}>
                                            <Typography sx={{ textAlign: 'center', fontSize: '15px' }}>Status</Typography>
                                        </Grid>
                                        <Grid item md={2} sx={{ padding: '20px 0px 20px 0px' }}>
                                            <Typography sx={{ textAlign: 'center', fontSize: '15px' }}>Amount</Typography>
                                        </Grid>
                                        <>
                                            {filteredTransactions?.map((transaction, index) => {
                                                const isEvenRow = index % 2 === 0;
                                                // const backgroundColor = transaction?.status='failed' isEvenRow ? '#EDF9FF' : '#fff';
                                                const backgroundColor = transaction?.status === "failed" ? '#FFCDD2' : (isEvenRow ? '#EDF9FF' : '#fff');

                                                return (
                                                    <React.Fragment key={index}>
                                                        <Grid item md={1} sx={{ position: 'relative', backgroundColor, padding: '20px 10px 20px 10px', borderRight: '1px solid grey', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                            <Typography sx={{ fontSize: '15px' }}>
                                                                {index + 1}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item md={3} sx={{ backgroundColor, padding: '20px 10px 20px 10px', borderRight: '1px solid grey' }}>
                                                            <Typography sx={{ fontSize: '12px', fontWeight: 'bold', textTransform: 'capitalize' }}>
                                                                {highlightText(transaction?.email, searchQuery)}<br />
                                                                {highlightText(transaction?.contact, searchQuery)} <br/><br/><br/>
                                                                <Box onClick={() => copyToClipboard(transaction)} sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                                                                    <span style={{ fontSize: '11px' }}>{transaction?.order_id}</span> 
                                                                    <ContentCopyOutlined sx={{ paddingLeft: '4px'}} />
                                                                </Box>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item md={2} sx={{ backgroundColor, padding: '20px 10px 20px 10px', display: 'flex', justifyContent: 'center', borderRight: '1px solid grey', alignItems: 'center' }}>
                                                            <Typography sx={{ fontSize: '15px', textTransform: 'uppercase' }}>{highlightText(transaction?.method, searchQuery)}</Typography>
                                                        </Grid>
                                                        <Grid item md={2} sx={{ backgroundColor, padding: '20px 10px 20px 10px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRight: '1px solid grey' }}>
                                                            <Typography sx={{ fontSize: '15px', }}>{highlightText(transaction?.acquirer_data?.rrn || 'N/A', searchQuery)}</Typography>
                                                        </Grid>
                                                        <Grid item md={2} sx={{ backgroundColor, padding: '20px 10px 20px 10px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRight: '1px solid grey' }}>
                                                            <Typography sx={{ fontSize: '15px', textTransform: 'uppercase' }}>{highlightText(transaction?.status, searchQuery)}</Typography>
                                                        </Grid>
                                                        <Grid item md={2} sx={{ backgroundColor, padding: '20px 10px 20px 10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                            <Typography sx={{ fontSize: '15px', }}>₹{highlightText(String(transaction?.amount / 100), searchQuery)}</Typography>
                                                        </Grid>
                                                        {/* <Grid item md={12}></Grid> */}
                                                    </React.Fragment>
                                                );
                                            })}
                                        </>
                                    </Grid>
                                </>
                            </>
                        ) : (
                            <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '200px',
                            }}
                        >
                            <Typography>No records found</Typography>
                        </Box>
                        )}
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}

export default PaymentTransactions;