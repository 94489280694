import { Box, Grid, Stack, Toolbar, Typography, Dialog, DialogContent, DialogTitle, TextField, IconButton, Select, MenuItem, FormControl, InputLabel, } from '@mui/material';
import React, { useEffect, useState } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import Images from '../../utils/Images';
import axios from 'axios';
import { API } from '../../utils/Api';
import { useSelector } from 'react-redux';
import { AccountBalanceOutlined, ChevronRight, EditNote, SummarizeOutlined, ToggleOffOutlined, ToggleOnOutlined } from '@mui/icons-material';
import ManagerCalendar from '../../components/ManagerCalendar';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

function ManagerDashboard() {
  const token = localStorage.getItem('token');
  const navigate = useNavigate();
  const [products, setProducts] = useState([]); 
  const [filteredProducts, setFilteredProducts] = useState([]); 
  const [loading, setLoading] = useState(false);
  const [openRecords, setOpenRecords] = useState(false);
  const userData = useSelector((state) => state.login.user);
  const [cashierRecords, setCashierRecords] = useState([]);
  const [selectedCashierId, setSelectedCashierId] = useState(null);
  const [dashboardData, setDashboardData] = useState([]);
  const [selectedCashierName, setSelectedCashierName] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('All');
  const storedOutletData = localStorage.getItem('outletData');
  let totalIndex = 0;

  const outletData = JSON.parse(storedOutletData);

  const fetchData = async () => {
    try {
      const url = `${API.BASE_URL}outlet/user/getShiftRecordsForCashiers`;
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setCashierRecords(response?.data?.data?.cashiers || []);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const getDashboardParams = async () => {
    try {
      setLoading(true);
      const requestUrl = `${API.BASE_URL}outlet/dashboard-params`;
      const response = await axios.get(requestUrl, {
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log('Response of dashboard params', response.data.data);
      setDashboardData(response?.data?.data);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  const getProductsList = async () => {
    try {
      setLoading(true);
      const requestUrl = `${API.BASE_URL}masters/category/getCategoryWithProducts/${outletData?._id}`;
      const response = await axios.get(requestUrl, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const categoriesWithProducts = response.data.data.categories;
      setProducts(categoriesWithProducts);
      setFilteredProducts(categoriesWithProducts);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  const categories = products.map((category) => ({
    id: category._id,
    name: category.name,
  }));

  const handleCategoryChange = (event) => {
    const categoryId = event.target.value;
    setSelectedCategory(categoryId);

    if (categoryId === 'All') {
      // Show all products
      setFilteredProducts(products);
    } else {
      // Filter products by selected category
      const filtered = products.filter((category) => category._id === categoryId);
      setFilteredProducts(filtered);
    }

    // Apply search filter if search query exists
    if (searchQuery) {
      handleSearch(searchQuery, categoryId);
    }
  };

  const handleSearch = (searchText) => {
    setSearchQuery(searchText);

    let filtered = products;

    if (selectedCategory !== 'All' && selectedCategory) {
      filtered = products.filter((category) => category._id === selectedCategory);
    }

    if (searchText) {
      filtered = filtered
        .map((category) => ({
          ...category,
          products: category.products.filter((product) =>
            product.name.toLowerCase().includes(searchText.toLowerCase())
          ),
        }))
        .filter((category) => category.products.length > 0);
    }

    setFilteredProducts(filtered);
  };

  const handleSwitchChange = async (productId, isExhausted) => {
    const url = isExhausted
      ? `${API.BASE_URL}product/exhausted`
      : `${API.BASE_URL}product/undo-exhausted`;
    const method = isExhausted ? 'post' : 'delete';

    try {
      await axios({
        method,
        url,
        data: { product: productId },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Update the products in the frontend
      setProducts((prevProducts) =>
        prevProducts.map((category) => ({
          ...category,
          products: category.products.map((product) =>
            product._id === productId ? { ...product, isExhausted } : product
          ),
        }))
      );

      // Also update the filtered products
      setFilteredProducts((prevFiltered) =>
        prevFiltered.map((category) => ({
          ...category,
          products: category.products.map((product) =>
            product._id === productId ? { ...product, isExhausted } : product
          ),
        }))
      );

      toast.success(
        isExhausted ? 'Product set as exhausted!' : 'Product set as back in stock!',
        {
          autoClose: 2000,
        }
      );
    } catch (error) {
      const errorMessage = error.response?.data?.data?.message || 'An error occurred';
      console.error(
        isExhausted ? 'Error in setting exhausted:' : 'Error in undoing exhausted:',
        errorMessage
      );
      toast.error(errorMessage, {
        autoClose: 2000,
      });
    }
  };

  const handleOpenRecordsModal = async (cashierId) => {
    setSelectedCashierId(cashierId);
    const selectedCashier = cashierRecords?.find((cashier) => cashier._id === cashierId);
    if (selectedCashier) {
      setSelectedCashierName(selectedCashier.name);
    }
    setOpenRecords(true);
  };

  const handleCloseRecordsModal = () => {
    setOpenRecords(false);
  };

  useEffect(() => {
    fetchData();
    getProductsList();
    getDashboardParams();
  }, []);

  return (
    <>
      <Box className="dashBoard">
        <Toolbar />
        <Grid container>
          <Grid item md={7} flexDirection={'column'}>
            <Grid
              container
              spacing={3}
              md={12}
              sx={{
                borderRadius: '10px',
                padding: '0px 20px 15px 0px',
                margin: '50px 0px 0 10px',
              }}
            >
              <Grid item md={4}>
                <Box
                  sx={{
                    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
                    backgroundColor: '#fff',
                    borderRadius: '8px',
                    padding: '25px 20px 15px 20px',
                    justifyContent: 'center',
                    textAlign: 'center',
                    height: '100%'
                  }}
                >
                  <AccountCircleOutlinedIcon sx={{ fontSize: '55px' }} />
                  <Typography sx={{ color: '#000', fontSize: '20px' }}>
                    {userData?.user?.name}
                  </Typography>
                  <Box
                    sx={{
                      backgroundColor: '#003049',
                      color: '#fff',
                      padding: '10px 0px 10px 0px',
                      borderRadius: '30px',
                    }}
                  >
                    <Typography sx={{ fontSize: '14px' }}>Manager</Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid 
                item 
                md={8} 
                display="flex" 
                justifyContent="space-between"
              >
                <Box
                  sx={{
                    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
                    backgroundColor: '#fff',
                    borderRadius: '8px',
                    padding: '15px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '48%',
                  }}
                >
                  <Typography
                    sx={{ 
                      color: '#000', 
                      fontSize: '50px', 
                      fontWeight: 'bold', 
                      wordWrap: 'break-word' 
                    }}
                  >
                    {dashboardData?.totalRecords || 0}
                  </Typography>
                  <Box sx={{ padding: '10px 0 0 10px' }}>
                    <Typography sx={{ fontSize: '16px', color: '#777' }}>
                      Total Orders
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      padding: '5px 10px',
                      display: 'flex',
                      alignItems: 'center',
                      borderRadius: '20px',
                      backgroundColor: '#f5f5f5',
                      mt: '10px',
                      cursor: 'pointer',
                      transition: 'all 0.3s ease',
                      '&:hover': {
                        backgroundColor: '#e0e0e0',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                      },
                    }}
                    onClick={() => navigate('/ordersReport')}
                  >
                    <SummarizeOutlined sx={{ fontSize: '16px', color: '#555', mr: '5px' }} />
                    <Typography
                      sx={{
                        fontSize: '12px',
                        color: '#555',
                        fontWeight: '500',
                      }}
                    >
                      View Reports
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
                    backgroundColor: '#fff',
                    borderRadius: '8px',
                    padding: '15px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '48%'
                  }}
                >
                  <Typography sx={{ color: '#000', fontSize: '30px', fontWeight: 'bold' }}>
                    ₹{dashboardData?.totalGrandTotal?.toFixed(0) || 0}
                  </Typography>
                  <Box sx={{ padding: '10px 0' }}>
                    <Typography sx={{ fontSize: '16px', color: '#777' }}>Total Sales</Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Grid container sx={{ margin: '16px 0 0 20px'  }}>
              <Grid item md={4}>
                <Box
                  sx={{
                    padding: '5px 10px',
                    display: 'flex',
                    alignItems: 'center',
                    borderRadius: '20px',
                    backgroundColor: '#ffb3b3',
                    mt: '10px',
                    cursor: 'pointer',
                    justifyContent: 'center',
                    transition: 'all 0.3s ease',
                    '&:hover': {
                      backgroundColor: '#e0e0e0',
                      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    },
                  }}
                  onClick={() => navigate('/criticalReport')}
                >
                  <SummarizeOutlined sx={{ fontSize: '16px', color: '#000', mr: '5px' }} />
                  <Typography
                    sx={{
                      fontSize: '12px',
                      color: '#000',
                      fontWeight: '500',
                    }}
                  >
                    Critical Stock Reports
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={4} ml={2}>
                <Box
                  sx={{
                    padding: '5px 10px',
                    display: 'flex',
                    alignItems: 'center',
                    borderRadius: '20px',
                    backgroundColor: '#e0e0e0',
                    mt: '10px',
                    cursor: 'pointer',
                    justifyContent: 'center',
                    transition: 'all 0.3s ease',
                    '&:hover': {
                      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    },
                  }}
                  onClick={() => navigate('/paymentTransactions')}
                >
                  <AccountBalanceOutlined sx={{ fontSize: '16px', color: '#000', mr: '5px' }} />
                  <Typography
                    sx={{
                      fontSize: '12px',
                      color: '#000',
                      fontWeight: '500',
                    }}
                  >
                    Payment Transactions
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={3} ml={2}>
                <Box
                  sx={{
                    padding: '5px 10px',
                    display: 'flex',
                    alignItems: 'center',
                    borderRadius: '20px',
                    backgroundColor: '#e0e0e0',
                    mt: '10px',
                    cursor: 'pointer',
                    justifyContent: 'center',
                    transition: 'all 0.3s ease',
                    '&:hover': {
                      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    },
                  }}
                  onClick={() => navigate('/createOrder')}
                >
                  <EditNote sx={{ fontSize: '16px', color: '#000', mr: '5px' }} />
                  <Typography
                    sx={{
                      fontSize: '12px',
                      color: '#000',
                      fontWeight: '500',
                    }}
                  >
                    Create Order
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid container md={11} sx={{ marginTop: '15px' }}>
              <Grid item md={3}>
                <Typography sx={{ fontSize: '15px', margin: '16px 0 0 40px' }}>
                  Stock Inventory
                </Typography>
              </Grid>
              <Grid item md={6}>
                <TextField
                  fullWidth
                  sx={{
                    backgroundColor: '#fff',
                    marginTop: '5px',
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '20px',
                    },
                    '& input': {
                      height: '10px',
                    },
                  }}
                  placeholder="Search"
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  value={searchQuery}
                  onChange={(e) => {
                    handleSearch(e.target.value);
                  }}
                />
              </Grid>
              <Grid item md={3} sx={{ display: 'flex', alignItems: 'center' }}>
                <FormControl fullWidth sx={{ marginLeft: '7px' }} size="small" >
                  <InputLabel>Category</InputLabel>
                  <Select
                    value={selectedCategory}
                    label="Category"
                    onChange={handleCategoryChange}
                    size='small'
                  >
                    <MenuItem value="All">All</MenuItem>
                    {categories.filter(category => outletData?.isEventOutlet ? category?.isEventCategory : !category?.isEventCategory).map((category) => (
                      <MenuItem key={category.id} value={category.id}>
                        {category.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container md={11} sx={{ boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)', borderRadius: '8px', padding: '0px 0px 0px 0px', margin: '21px 0px 25px 40px', }}>
              {filteredProducts.length === 0 ? (
                <Typography sx={{ textAlign: 'center', padding: '20px', fontSize: '16px' }}>
                  No products found.
                </Typography>
              ) : (
                <>
                  <Grid
                    item
                    md={2}
                    sx={{ padding: '20px 10px 20px 10px', borderRight: '1px solid grey' }}
                  >
                    <Typography sx={{ textAlign: 'center', fontSize: '15px' }}>
                      Item No
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    sx={{ padding: '20px 0px 20px 0px', borderRight: '1px solid grey' }}
                  >
                    <Typography sx={{ textAlign: 'center', fontSize: '15px' }}>
                      Product
                    </Typography>
                  </Grid>
                  <Grid item md={4} sx={{ padding: '20px 0px 20px 0px' }}>
                    <Typography sx={{ textAlign: 'center', fontSize: '15px' }}>
                      Set as Out of Stock
                    </Typography>
                  </Grid>
                  {filteredProducts.map((category) => (
                    <div key={category._id}>
                      <Grid container>
                        {category.products.filter(product => outletData?.isEventOutlet ? product.isEventProduct : !product.isEventProduct).map((product, index) => {
                        totalIndex += 1;
                          return (
                            <React.Fragment key={product._id}>
                              <Grid item md={2} sx={{ backgroundColor: '#EDF9FF', padding: '20px 10px', borderRight: '1px solid grey', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Typography sx={{ fontSize: '14px' }}>{totalIndex}</Typography>
                              </Grid>
                              <Grid
                                item
                                md={6}
                                sx={{
                                  backgroundColor: '#EDF9FF',
                                  padding: '20px 0',
                                  borderRight: '1px solid grey',
                                }}
                              >
                                <Stack
                                  direction="row"
                                  sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                                >
                                  <Box sx={{ width: '50%', textAlign: 'center' }}>
                                    <img
                                      src={product.displayImageUrl || Images.product}
                                      alt={product.name}
                                      style={{ height: 'auto', width: '50%' }}
                                      onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = Images.product;
                                      }}
                                    />
                                  </Box>
                                  <Box sx={{ width: '50%' }}>
                                    <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                      {product.name}
                                    </Typography>
                                    <Typography sx={{ fontSize: '14px' }}>
                                      Price: ₹{product.mrp}
                                    </Typography>
                                  </Box>
                                </Stack>
                              </Grid>
                              <Grid
                                item
                                md={4}
                                sx={{
                                  backgroundColor: '#EDF9FF',
                                  padding: '20px 0',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  flexDirection: 'column',
                                  alignItems: 'center',
                                }}
                              >
                                <IconButton
                                  onClick={() => handleSwitchChange(product._id, !product.isExhausted)}
                                >
                                  {product.isExhausted ? (
                                    <ToggleOnOutlined style={{ fontSize: '30px', color: 'red' }} />
                                  ) : (
                                    <ToggleOffOutlined style={{ fontSize: '30px', color: 'green' }} />
                                  )}
                                </IconButton>
                                <Typography sx={{ textAlign: 'center', fontSize: '13px' }}>
                                  {product.isExhausted
                                    ? 'Set as in Stock'
                                    : 'Set as Out of Stock'}
                                </Typography>
                              </Grid>
                            </React.Fragment>
                          )
                        })}
                      </Grid>
                    </div>
                  ))}
                </>
              )}
            </Grid>
          </Grid>
          <Grid item md={5}>
            <Grid
              container
              md={12}
              sx={{ borderRadius: '8px', margin: '50px 0px 0 20px' }}
            >
              <Grid
                item
                md={11}
                sx={{
                  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
                  backgroundColor: '#fff',
                  borderRadius: '4px',
                  padding: '15px 0px 20px 0px',
                }}
              >
                <Typography
                  sx={{
                    color: '#000',
                    fontSize: '18px',
                    textAlign: 'center',
                    paddingBottom: '10px',
                  }}
                >
                  Manage Cashiers
                </Typography>
                {cashierRecords?.map((cashier, index) => (
                  <Box
                    key={cashier._id}
                    sx={{
                      backgroundColor: index % 2 === 0 ? '#EDF9FF' : '#FFF',
                      padding: '10px 20px 15px 20px',
                    }}
                  >
                    <Typography
                      sx={{ color: '#000', fontSize: '14px', fontWeight: '600' }}
                    >
                      {cashier.name}
                    </Typography>
                    <Typography
                      sx={{ color: '#000', fontSize: '11px', paddingBottom: '8px' }}
                    >
                      In Out Time today
                    </Typography>
                    <Grid container justifyContent={'space-around'}>
                      <Grid
                        item
                        md={7}
                        sx={{
                          backgroundColor: index % 2 === 0 ? '#FFF' : '#EDF9FF',
                          borderRadius: '5px',
                          padding: '12px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Stack direction={'row'}>
                          <Typography sx={{ fontWeight: '600', fontSize: '12px' }}>
                            In : 8:02am
                          </Typography>
                          <Typography
                            sx={{ marginLeft: '12px', fontWeight: '600', fontSize: '12px' }}
                          >
                            Out : 8:02am
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid
                        item
                        md={4}
                        className="cursor-p"
                        onClick={() => handleOpenRecordsModal(cashier._id)}
                        sx={{
                          backgroundColor: index % 2 === 0 ? '#FFF' : '#EDF9FF',
                          borderRadius: '5px',
                          padding: '6px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Stack direction={'row'}>
                          <Typography sx={{ fontWeight: '600', fontSize: '10px' }}>
                            See previous <br />
                            records
                          </Typography>
                          <ChevronRight />
                        </Stack>
                      </Grid>
                    </Grid>
                  </Box>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Dialog open={openRecords} onClose={handleCloseRecordsModal} maxWidth="lg">
        <DialogTitle>Shift Record : {selectedCashierName}</DialogTitle>
        <DialogContent>
          <ManagerCalendar selectedCashierId={selectedCashierId} />
        </DialogContent>
      </Dialog>
    </>
  );
}

export default ManagerDashboard;
